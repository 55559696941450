import React from 'react'
import { Link } from 'react-router-dom'
import { FormInner, FormContainer, Form, FormHeading, FieldsWrapper, FieldDiv, PlanDetailsDiv, SubmitWrapper } from '../styled-elements'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import { RoundedFormButton, RoundedOutlineButton } from '../../../styles/styled-elements'
import { compareArrays } from '../../../utils/arrayUtils'
import { DateTime } from "luxon"

const classes = () => {

  const useStyles = makeStyles(theme => ({
    // radio: {
    //   root: {
    //     padding: 29,
    //   }
    // }
  }))

  return useStyles

}

const PlanAndExtrasConfirmationForm = ({ state, billingItemAccessPlan, setStartFreeTrialConfirmFormVisibility, confirmHandler }) => {

  let planName = ''
  let monthlyChargeExTax

  const { primaryBillingProductKey, primaryBillingProductAws, additionalAwsSlugs, additionalAwss } = state

  switch(primaryBillingProductKey) {
    case 'free_access_1':
      planName='Free Access'
      monthlyChargeExTax=0
      break
    case 'standard_bundle_1':
      planName='Standard Bundle'
      monthlyChargeExTax=200
      break
    case 'farm_bundle_1':
      planName='Farm Bundle'
      monthlyChargeExTax=365
      break
    case 'premium_bundle_1':
      planName='Premium Bundle'
      monthlyChargeExTax=540
      monthlyChargeExTax+=(90*state.additionalAwss.length)
      break
  }

  const newChargeAmountExTaxNumber = monthlyChargeExTax
  const newChargeAmountExTaxStr = `AUD $${monthlyChargeExTax.toFixed(2)}`
  const newChargeAmountIncTaxNumber = monthlyChargeExTax*1.1
  const newChargeAmountIncTaxStr = `$${newChargeAmountIncTaxNumber.toFixed(2)}`

  const firstBilledAt = (billingItemAccessPlan && billingItemAccessPlan.first_billed_at) ? DateTime.fromISO(billingItemAccessPlan.first_billed_at) : null

  const additionalAwsSentencePrep = (additionalAwss.length > 1) ? 'AWSs are' : 'AWS is'

  if (!billingItemAccessPlan || !firstBilledAt) {

    return <div style={{ marginBottom: 60 }}>
      <h3>Confirmation</h3>
      <p>You have chosen the <strong>{planName}</strong> plan.</p>
      {((primaryBillingProductKey==="premium_bundle_1")) && <p>Your nominated AWS is <strong>{primaryBillingProductAws.label}</strong>.</p>}
      {((primaryBillingProductKey==="premium_bundle_1") && (additionalAwss.length)) ? <p>Your additional nominated {additionalAwsSentencePrep}:</p> : null}
      {((primaryBillingProductKey==="premium_bundle_1") && (additionalAwss.length)) ? <ul>{additionalAwss.map(({label},index) => {
        return <li key={index.toString()}>{label}</li>
      })}</ul> : null}
      <p>Your new plan's annual fee is <strong>{newChargeAmountExTaxStr} ex-GST ({newChargeAmountIncTaxStr} inc-GST).</strong></p>
      {newChargeAmountExTaxNumber && <p>You will be invoiced when your trial period ends or when you commence billing.</p>}
      <div>
        <RoundedOutlineButton enabled onClick={setStartFreeTrialConfirmFormVisibility.bind(this,false)} name="Cancel" type="button">Cancel</RoundedOutlineButton> &nbsp;
        <RoundedFormButton enabled onClick={confirmHandler.bind(this)}>Save</RoundedFormButton>
      </div>
    </div>

  }

  const currentAdditionalAwss = billingItemAccessPlan.additional_aws_objects
  const currentAdditionalAwsDetails = billingItemAccessPlan.additional_aws_details || {}

  const currentChargeAmountExTaxNumber = billingItemAccessPlan.price_yearly_recurring_ex_tax + (currentAdditionalAwss.length*(currentAdditionalAwsDetails.price_yearly_recurring_ex_tax || 0))
  const currentChargeAmountIncTaxNumber = currentChargeAmountExTaxNumber*1.1

  const currentPaidUntil = billingItemAccessPlan.paid_until ? DateTime.fromISO(billingItemAccessPlan.paid_until).setZone('Australia/Adelaide') : null
  const lastBilledAt = billingItemAccessPlan.last_billed_at ? DateTime.fromISO(billingItemAccessPlan.last_billed_at).setZone('Australia/Adelaide') : null
  const nextBillableAt = billingItemAccessPlan.next_billable_at ? DateTime.fromISO(billingItemAccessPlan.next_billable_at).setZone('Australia/Adelaide') : null

  const changeDirection = (newChargeAmountExTaxNumber > currentChargeAmountExTaxNumber) ? 'up' : 'down'

  if (changeDirection==='up') {

    const dateTimeNow = DateTime.now().setZone('Australia/Adelaide')

    const msDiff = nextBillableAt.toMillis()-dateTimeNow.toMillis()
    const diffDays = Math.floor(msDiff/86400000)
    let refundPortionExTaxNumber = 0
    if (lastBilledAt) {
      const billingDatesDiff = nextBillableAt.diff(lastBilledAt,'days')
      const unusedPortion = diffDays/billingDatesDiff.values.days
      refundPortionExTaxNumber = currentChargeAmountExTaxNumber*unusedPortion
    }
    const refundPortionExTaxStr = `AUD $${refundPortionExTaxNumber.toFixed(2)}`
    const refundPortionIncTaxNumber = refundPortionExTaxNumber*1.1
    const refundPortionIncTaxStr = `$${refundPortionIncTaxNumber.toFixed(2)}`

    const totalChargeAmountExTax = newChargeAmountExTaxNumber-refundPortionExTaxNumber
    const totalChargeAmountIncTax = newChargeAmountIncTaxNumber-refundPortionIncTaxNumber
    const totalChargeAmountExTaxStr = `AUD $${totalChargeAmountExTax.toFixed(2)}`
    const totalChargeAmountIncTaxStr = `$${totalChargeAmountIncTax.toFixed(2)}`

    let cardIsDeclining = false
    let declineError = ''
    if (state.errors && state.errors.length) {
      const paymentError = state.errors.find(({field_name}) => field_name==='payment')
      if (paymentError) {
        if (paymentError.messages.find((item) => item==='card_declined')) {
          cardIsDeclining = true
          declineError = paymentError.decline_code
        }
      }
    }

    return <div style={{ marginBottom: 60 }}>
      <h3>Confirmation</h3>

      {(cardIsDeclining) && <div style={{ border: '1px solid black', marginBottom: '20px', backgroundColor: 'rgba(255, 46, 56, 0.3)', fontSize: '0.9em', padding: '10px' }}>
        Your payment was unsuccessful. Please check your credit card balance or enter a new card via the <Link to={'/my/account/payment_details'}>Payment Details</Link> page, and try again.
      </div>}

      <p>You have chosen to upgrade to the <strong>{planName}</strong> plan.</p>
      {((primaryBillingProductKey==="premium_bundle_1")) && <p>Your nominated AWS is <strong>{primaryBillingProductAws.label}</strong>.</p>}
      {((primaryBillingProductKey==="premium_bundle_1") && (additionalAwss.length)) ? <p>Your additional nominated {additionalAwsSentencePrep}:</p> : null}
      {((primaryBillingProductKey==="premium_bundle_1") && (additionalAwss.length)) ? <ul>{additionalAwss.map(({label},index) => {
        return <li key={index.toString()}>{label}</li>
      })}</ul> : null}
      <p>As you are upgrading your plan, your plan changed immediately and you will be invoiced and charged for a year's subscription at the new rate, minus the unused portion of your current plan.</p>
      <p>Your new plan's annual fee is <strong>{newChargeAmountExTaxStr} ex-GST ({newChargeAmountIncTaxStr} inc-GST).</strong></p>
      {(refundPortionIncTaxNumber) ? <p>You will be rebated <strong>{refundPortionExTaxStr} ex-GST ({refundPortionIncTaxStr} inc-GST)</strong> for the unused portion of your current plan.</p> : null }
      <p>If you wish to proceed with this plan change, you will be charged <strong>{totalChargeAmountExTaxStr} ex-GST ({totalChargeAmountIncTaxStr} inc-GST)</strong> immediately.</p>
      <div>
        <RoundedOutlineButton enabled onClick={setStartFreeTrialConfirmFormVisibility.bind(this,false)} name="Cancel" type="button">Cancel</RoundedOutlineButton> &nbsp;
        <RoundedFormButton enabled onClick={confirmHandler.bind(this)}>Upgrade Plan and Pay {totalChargeAmountIncTaxStr} Now</RoundedFormButton>
      </div>
    </div>
  } else if (changeDirection==='down') {
    return <div style={{ marginBottom: 60 }}>
      <h3>Confirmation</h3>
      <p>You have chosen to downgrade to the <strong>{planName}</strong> plan.</p>
      <p>As you are downgrading your plan, your plan change is scheduled for your next billing date.</p>
      <p>Your next billing date is <strong>{nextBillableAt.toFormat('dd LLL yyyy')}</strong>.</p>
      <p>On that date you will be charged <strong>{newChargeAmountExTaxStr} ex-GST ({newChargeAmountIncTaxStr} inc-GST).</strong></p>
      <p>You will have continued use of your current plan until then.</p>
      <div>
        <RoundedOutlineButton enabled onClick={setStartFreeTrialConfirmFormVisibility.bind(this,false)} name="Cancel" type="button">Cancel</RoundedOutlineButton> &nbsp;
        <RoundedFormButton enabled onClick={confirmHandler.bind(this)}>Confirm Scheduled Plan Change</RoundedFormButton>
      </div>
    </div>
  }

}

export default class PlanAndExtrasForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      primaryBillingProductKey: null,
      primaryBillingProductAws: null,
      additionalAwsSlugs: [],
      additionalAwss: [],
      origValues: {
        primaryBillingProductKey: null,
        primaryBillingProductAws: null,
        additionalAwsSlugs: null,
      },
      showStartFreeTrialConfirmForm: false,
      hasChanged: false,
      canSubmit: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidMount(prevProps) {
    this.populateForm()
  }

  componentDidUpdate(prevProps,prevState) {
    const { props, state } = this
    const { primaryBillingProductKey, primaryBillingProductAws, additionalAwsSlugs, origValues } = state
    const stateHash = {}
    let hasChanged = false
    let canSubmit = false
    if (this.state!==prevState) {
      const primaryBillingProductAwsKey = primaryBillingProductAws && primaryBillingProductAws.value
      const propsPrimaryBillingProductAwsKey = props.primaryBillingProductAws && props.primaryBillingProductAws.value
      const origPrimaryBillingProductAwsKey = origValues.primaryBillingProductAws && origValues.primaryBillingProductAws.value
      if (primaryBillingProductKey!==props.primaryBillingProductKey && primaryBillingProductKey!==origValues.primaryBillingProductKey) hasChanged = true
      if (primaryBillingProductAwsKey!==propsPrimaryBillingProductAwsKey && primaryBillingProductAwsKey!==origPrimaryBillingProductAwsKey) hasChanged = true
      if (!compareArrays(additionalAwsSlugs,props.additionalAwsSlugs) && !compareArrays(additionalAwsSlugs,origValues.additionalAwsSlugs)) hasChanged = true
      if (hasChanged!==state.hasChanged) {
        stateHash['hasChanged'] = hasChanged
      }
      if ((primaryBillingProductKey==='premium_bundle_1') && primaryBillingProductAws) {
        canSubmit = true
      } else if (primaryBillingProductKey) {
        canSubmit = true
      }
      if (canSubmit!==state.canSubmit) {
        stateHash['canSubmit'] = canSubmit
      }
    }
    if (this.props!==prevProps) {
      const propsPrimaryBillingProductAwsKey = props.primaryBillingProductAws && props.primaryBillingProductAws.value
      const prevPropsPrimaryBillingProductAwsKey = prevProps.primaryBillingProductAws && prevProps.primaryBillingProductAws.value
      if ((props.primaryBillingProductKey!==prevProps.primaryBillingProductKey) ||
        (propsPrimaryBillingProductAwsKey!==prevPropsPrimaryBillingProductAwsKey) ||
        (!compareArrays(props.additionalAwsSlugs,prevProps.additionalAwsSlugs))) {

        stateHash['primaryBillingProductKey'] = props.primaryBillingProductKey || ''
        stateHash['primaryBillingProductAws'] = props.primaryBillingProductAws || ''
        stateHash['additionalAwsSlugs'] = props.additionalAwsSlugs || []
        stateHash['additionalAwss'] = props.additionalAwsObjects || []
        const origValuesHash = {}
        origValuesHash['primaryBillingProductKey'] = stateHash['primaryBillingProductKey']
        origValuesHash['primaryBillingProductAws'] = stateHash['primaryBillingProductAws']
        origValuesHash['additionalAwsSlugs'] = stateHash['additionalAwsSlugs']
        stateHash['origValues'] = origValuesHash
        stateHash['errors'] = props.errors
        stateHash['hasChanged'] = false
      }
      if (props.errors!==prevProps.errors) {
        stateHash['errors'] = props.errors
      }
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash)
    }
  }

  siteSlugToAutocompleteItem = (siteSlug) => {
    const siteOption = this.props.siteOptions.find(({value}) => value===siteSlug) || null
    return siteOption
  }

  populateForm = () => {
    const { props } = this
    const stateHash = {}
    stateHash['primaryBillingProductKey'] = props.primaryBillingProductKey || ''
    stateHash['primaryBillingProductAws'] = props.primaryBillingProductAws || ''
    stateHash['additionalAwsSlugs'] = props.additionalAwsSlugs || []
    stateHash['additionalAwss'] = props.additionalAwsObjects || []
    const origValuesHash = {}
    origValuesHash['primaryBillingProductKey'] = stateHash['primaryBillingProductKey']
    origValuesHash['primaryBillingProductAws'] = stateHash['primaryBillingProductAws']
    origValuesHash['additionalAwsSlugs'] = stateHash['additionalAwsSlugs']
    stateHash['origValues'] = origValuesHash
    stateHash['errors'] = props.errors
    stateHash['canSubmit'] = false
    this.setState(stateHash, () => {
    })
  }

  validate = () => {
    const errors = {}
    const { primaryBillingProductKey, primaryBillingProductAws, additionalAwss } = this.state
    if ((primaryBillingProductKey==='premium_bundle_1') && (!primaryBillingProductAws.value)) {
      errors.primaryBillingProductAws = 'Selected AWS is required'
    }
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      this.setState({
        showStartFreeTrialConfirmForm: true,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { hasChanged, canSubmit } = this.state
    if (!hasChanged || !canSubmit) return
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    this.validate()
  }

  setStartFreeTrialConfirmFormVisibility = (visibility) => {
    this.setState({ showStartFreeTrialConfirmForm: !!visibility })
  }

  handleConfirm = () => {
    const { primaryBillingProductKey, primaryBillingProductAws, additionalAwss } = this.state
    const primaryBillingProductAwsSlug = (primaryBillingProductAws && (primaryBillingProductKey==='premium_bundle_1')) ? primaryBillingProductAws.value : null
    const additionalAwsSlugs = (primaryBillingProductKey==='premium_bundle_1') ? additionalAwss.map(({value}) => value)  : []
    const params = { primaryBillingProductKey, primaryBillingProductAwsSlug, additionalAwsSlugs }
    this.props.submitHandler.call(this,params)
  }

  setPrimaryBillingProductKeyValue = (newValue) => {
    this.setState({ primaryBillingProductKey: newValue }, () => {
    })
  }

  setSelectedAwsValue = (newValue) => {
    this.setState({ primaryBillingProductAws: newValue }, () => {
    })
  }

  setAdditionalAwssValue = (newValue) => {
    if (!newValue) return
    if (newValue.length > 3) return
    const updateValue = []
    newValue.forEach((newItem) => {
      if (newItem.value===this.state.primaryBillingProductAws.value) return
      if (!updateValue.find((existingItem) => existingItem.value===newItem.value)) {
        updateValue.push(newItem)
      }
    })
    const additionalAwsSlugs = updateValue.map(({value}) => value)
    this.setState({ additionalAwss: updateValue, additionalAwsSlugs: additionalAwsSlugs },() => {
    })
  }

  renderBody = (props,state) => {

    if (state.showStartFreeTrialConfirmForm) {
      return <PlanAndExtrasConfirmationForm
        state={state}
        billingItemAccessPlan={props.billingItemAccessPlan}
        setStartFreeTrialConfirmFormVisibility={this.setStartFreeTrialConfirmFormVisibility}
        confirmHandler={this.handleConfirm}
      />
    }

    return <FormContainer>
      <FormInner>
        <Form onSubmit={this.handleSubmit}>
          {this.state.formErrors &&
            <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{this.state.formErrors}</FormHelperText>
          }
          <FieldsWrapper>
            <FormControl>
              <FormLabel id="access-plan-radio-buttons-heading">Access Plan</FormLabel>
              <RadioGroup
                aria-labelledby="access-plan-radio-buttons-heading"
                defaultValue=""
                name="primaryBillingProductKey"
                onChange={(event, newValue) => {
                  this.setPrimaryBillingProductKeyValue(newValue)
                }}
                value={state.primaryBillingProductKey}
                style={{ padding: 10 }}
              >
                <FieldDiv>
                  <FormControlLabel value="free_access_1" control={<Radio classes={classes.radio} />} label="Free Access" />
                  <PlanDetailsDiv>
                    Includes: Temperature at 1.2m, Wind speed and direction at 10m
                  </PlanDetailsDiv>
                </FieldDiv>
                <FieldDiv>
                  <FormControlLabel value="standard_bundle_1" control={<Radio />} label="Standard Bundle – $200/year ex-GST" />
                  <PlanDetailsDiv>
                    Includes:<br />
                    Temperature at 1.2m, Wind speed and direction at 10m, Inversion: Vertical Temperature Difference (VTD) between 10m and 1.2m, Grain Harvest Code of Practice Index (GHCoP).
                  </PlanDetailsDiv>
                </FieldDiv>
                <FieldDiv>
                  <FormControlLabel value="farm_bundle_1" control={<Radio classes={classes.radio} />} label="Farm Bundle – $365/year ex-GST" />
                  <PlanDetailsDiv>
                    Includes Standard Bundle features, plus:<br />
                    Delta T, Rainfall, Relative Humidity, Wind speed and direction at 2m, Ability to set custom zoom and home location.
                  </PlanDetailsDiv>
                </FieldDiv>
                <FieldDiv>
                  <FormControlLabel value="premium_bundle_1" control={<Radio />} label="Premium Bundle – $540/year ex-GST" />
                  <PlanDetailsDiv>
                    Includes Farm Bundle features, plus:<br />
                    Dew point, Grass Fire Danger Index, Daily ET 0 (Crop evapotranspiration), Daily maximum and minimum temperatures, Hours below -2°C, 0°C and 2°C each night, Daily Crop Thermal Time, Daily Crop Vernal Time, Daily Adjusted Crop Thermal Time, Ability to personalise Growing Season based on sowing date and location, Extended time-history for all charts, Ability to download data history for nominated stations
                  </PlanDetailsDiv>
                </FieldDiv>
              </RadioGroup>
            </FormControl>
          </FieldsWrapper>
          <FieldsWrapper show={(this.state.primaryBillingProductKey==='premium_bundle_1')}>
            <FormControl>
              <FormLabel id="access-plan-radio-buttons-heading">Selected AWS</FormLabel>
              <FieldDiv style={{ padding: 10, paddingLeft: 20 }}>
                <Autocomplete
                  id="selected-aws-autocomplete-input"
                  name="primaryBillingProductAws"
                  options={props.siteOptions}
                  onChange={(event, newValue) => {
                    this.setSelectedAwsValue(newValue)
                  }}
                  value={state.primaryBillingProductAws}
                  isOptionEqualToValue={(a,b) => {
                    return (a.value===b.value)
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selected AWS (required)"
                      variant="standard"
                      placeholder="Type to search"
                      error={((state.errors && state.errors.primaryBillingProductAws) ? true : false)}
                    />
                  )}
                />
              </FieldDiv>
            </FormControl>
            <FormHelperText error>{state.errors && state.errors.primaryBillingProductAws}</FormHelperText>
          </FieldsWrapper>
          <FieldsWrapper show={(this.state.primaryBillingProductKey==='premium_bundle_1')}>
            <FormControl>
              <FormLabel id="access-plan-radio-buttons-heading">Up to 3 Additional Selected AWSs – $90/year ex-GST each </FormLabel>
              <FieldDiv style={{ padding: 10, paddingLeft: 20 }}>
                <Autocomplete
                  multiple
                  id="additional-awss-autocomplete-input"
                  options={props.siteOptions}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    this.setAdditionalAwssValue(newValue)
                  }}
                  value={state.additionalAwss}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Additional AWSs"
                      placeholder="Type to search"
                    />
                  )}
                />
              </FieldDiv>
            </FormControl>
          </FieldsWrapper>
          <SubmitWrapper>
            <RoundedOutlineButton enabled onClick={props.setSelectPlanFormVisibility.bind(this,false)} name="Cancel" type="button">Cancel</RoundedOutlineButton> &nbsp;
            <RoundedFormButton enabled={(state.hasChanged && state.canSubmit)}>Submit</RoundedFormButton>
          </SubmitWrapper>
        </Form>
      </FormInner>
    </FormContainer>
  }

  render() {
    return this.renderBody(this.props,this.state)
  }
}
